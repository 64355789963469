import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PageLink from "../components/pageLink"
import Tagline from "../components/tagline"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Reviews. Information. Opinions. Tutorials." />
    <h2>Welcome to Aj</h2>
    <Tagline />
    {data.allMarkdownRemark.edges.map(({ node }) => (
      <PageLink
        slug={node.fields.slug}
        title={node.frontmatter.title}
        description={node.frontmatter.description}
        date={node.frontmatter.date}
        category={node.frontmatter.category}
      />
    ))}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            description
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
